h1 {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 60px;
  letter-spacing: 0.25px;
}

h3 {
  margin: 0;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 38px
}

h4 {
  margin: 0;
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 22px
}

h4.centered {

}

h2 {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 40px;
  margin: 0;
}

.parent {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  font-family: 'neue-haas-grotesk-text', Helvetica, sans-serif;

  background: linear-gradient(60deg, rgba(2,0,36,1) 0%, rgb(9, 37, 121) 50%, rgb(10, 70, 81) 100%);
  overflow-x: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.centerParent {
  margin-left: 30px;
  margin-right: 30px;
  max-width: 925px;
  height: 100%;
}

.simpleCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.parent::-webkit-scrollbar {

  overflow-x: auto;
  display: none;
}

.topDivider {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pageTitle {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 60px;
  letter-spacing: 0.25px;

  margin: 10px;
}

.pageSubtitle {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 25px;
  margin: 10px;
  margin-right: 100px;
  margin-bottom: 10px;
  text-align: right;
}

.menubar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: x-large;
  padding-bottom: 30px;
  max-width: 100%;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  text-align: center;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a.nostyle:hover {
  text-decoration: underline !important;
}

a.underline:link {
  text-decoration: underline;
  color: inherit;
}

a.underline:visited {
  text-decoration: underline;
  color: inherit;
  cursor: auto;
}

a.underline:hover {
  text-decoration: underline;
}

.imageHalf {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageHalfMobile{
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img.portfolio {
  max-height: 400px;
  max-width: 400px;
  width: auto;
  height: auto;
  margin: 10px;
}

@media (min-width: 400px) {
  img.portfolio {
    max-width: 80vw;
  }
}

video.portfolio {
  max-height: 400px;
  width: 400px;
  margin: 10px;
}

@media (min-width: 400px) {
  video.portfolio {
    max-width: 80vw;
  }
}

.imageStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.imagetext {
  margin-right: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 20vh;
}

.description {
  font-size: 20px;
}

img.resume {
  width: 100%;
  border: 5px solid #555;
}



.download {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

hr.divider {
  margin-top: 50px;
  margin-bottom: 50px;
}

.skillContainer {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 20px;

}

li {
  margin-bottom: 10px;
}

.hidden {
  opacity: 0;
  filter: blur(2px);
  transition: all 500ms;
  transition-delay: 100ms;
}

.show {
  opacity: 1;
  transition: 500ms;
  transition-delay: 100ms;
  filter: blur(0);
}

img.hidden {
  opacity: 0;
  filter: blur(2px);
  transition: all 500ms;
  transition-delay: 100ms;
}

img.show {
  opacity: 1;
  transition: 500ms;
  transition-delay: 100ms;
  filter: blur(0);
}

@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
  
  .show {
    transition: none;
  }
  
  img.hidden {
    transition: none;
  }
  
  img.show {
    transition: none;
  }
}

img.qrcode {
  max-width: 40vw;
  max-height: 40vw;
}

img.qrcodeMobile {
  max-width: 80vw;

}

img.logoLink {
  height: 50px;
  -webkit-filter: brightness(100%);
  margin: 10px;
  padding-right: 80px;
}

img.logolinkMobile {
  height: 50px;
  -webkit-filter: brightness(100%);


}

img.logoLink:hover {
  -webkit-filter: brightness(70%);
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  transition: all 200ms ease;
}

.linkbar {
  margin-right: 30px;
  margin-left: 30px;
}
