.exterior {
    border-radius: 15px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 15px;
    width: 6em;
    height: fit-content;
    text-align: center;
    color: white;
    font-weight: 800;
    margin-bottom: 20px;


}

.logoRow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

img.icon {
    height: 3.5ch;
    margin: 5px;
    margin-right: 12px;
}

h4.name {
    margin: 0;
    font-family: neue-haas-grotesk-text, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    padding-right: 5px;
}

img.darkIcon {
    height: 3ch;
    margin: 5px;
    margin-right: 10px;
    background-color: black;
    border-radius: 2px;
    padding: 5px;

}

ul {
    padding-left: 25px;
    margin: 0;
    font-size: 16px;
}