.main {
    margin: 10px;
    background-color: #333;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: small;

    padding-right: 10px;
    padding-bottom: 5px;
}

.main:hover {
    background-color: rgb(28, 28, 28);
    transition: 100ms;
}

img.ghlogo {
    height: 30px;
    padding: 10px;
}

.textStack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

img.ghtext {
    height: 20px;
    padding-top: 5px;
}


a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
  }
  
  a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }